import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "~/util/css";

const buttonVariants = cva(
  "flex items-center justify-center rounded-full border-2 border-[#FFA9DE] disabled:opacity-50 cursor-pointer",
  {
    variants: {
      variant: {
        default:
          "text-black bg-[radial-gradient(111.32%_111.25%_at_53.26%_0%,_#CBE3FF_0%,_#AFFFE4_17.19%,_#FFA0F5_73.04%,_#FFF_100%)] hover:bg-[linear-gradient(0deg,rgba(255,255,255,0.50)_0%,rgba(255,255,255,0.50)_100%),radial-gradient(111.32%_111.25%_at_53.26%_0%,#CBE3FF_0%,#AFFFE4_17.19%,_#FFA0F5_73.04%,_#FFF_100%)]",
        outline: "bg-transparent",
        secondary:
          "border-primary text-black bg-[radial-gradient(116.25%_116.25%_at_53.26%_0%,#FFFFFF_0%,#CCFFCB_18.75%,#9AC8FF_50%,#FFFFFF_96.35%)] hover:bg-[linear-gradient(0deg,rgba(255,255,255,0.50)_0%,rgba(255,255,255,0.50)_100%),radial-gradient(116.35%_116.25%_at_53.26%_0%,_#FFF_0%,#CCFFCB_18.75%,_#9AC8FF_50%,#FFF_96.35%)]",
      },
      size: {
        default: "gap-2 py-2 px-4",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        role="button"
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = "Button";

export { Button, buttonVariants };
